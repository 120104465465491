import React from 'react'
import { Link } from 'gatsby'
import logo from '../assets/images/logo-light.svg'

const BannerAfter = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <img src={logo} alt="Logo Navíc" />
            <header className="major">
                <h1>Hra skončila</h1>
            </header>
            <div className="content">
				<p>Všem hráčům děkujeme za účast a doufáme, že jste si hru užili!</p>
                <ul>
					<li>V kategorii <i>Na čas</i> zvítězil tým <strong>Spící Volové</strong>, který dorazil do cíle ve 15.13.</li>
					<li>V kategorii <i>Víc bodů</i> zvítězil tým <strong>¯\_(ツ)_/¯</strong>, který získal <strong>plný počet</strong> 100 bodů.</li>
					<li>Další dokončivší najdete v <Link to="vysledky">oficiální výsledkové listině</Link>.</li>
					<li>Zveřejnili jsme <Link to="sifry">zadání šifer a jejich řešení</Link>.</li>
					<li>Uveřejnili jsme odkazy na <Link to="reportaze">reportáže a fotky</Link>.</li>
					<li>Prosíme, napište nám do <Link to="diskuse">diskuse</Link>. Komentovat můžete i pod šiframi.</li>
					<li><Link to="podekovani">Hodně lidí nám s přípravou hry pomohlo, děkujeme!</Link></li>
				</ul>

				<p>Vítězům a všem dalším týmům, které dorazily do cíle, gratulujeme!</p>
            </div>
        </div>
    </section>
)

export default BannerAfter
